<template>
  <div class="card-cell" :dir="language === 'ar' ? 'rtl' : 'ltr'">
    <div v-if="getFilters()" class="flex justify-center mb-3 flex-wrap">
      <div
        :class="{ 'bg-primary bg-opacity-100': 'All' === selectedFilter }"
        @click="selectFilter('All')"
        class="flex-auto px-3 py-2 rounded-md text-white bg-black bg-opacity-30 cursor-pointer m-1">
        {{ language === 'ar' ? 'الكل' : 'All' }}
      </div>
      <div
        v-for="(filter, i) in getFilters()"
        :key="i"
        @click="selectFilter(filter)"
        :class="{ 'bg-primary bg-opacity-100': filter === selectedFilter }"
        class="flex-auto px-3 py-2 rounded-md text-white bg-black bg-opacity-30 cursor-pointer m-1">
        {{ filter }}
      </div>
    </div>

    <div v-if="filteredCards.length > 1" class="swiper-container" v-carousel>
      <div class="swiper-wrapper">
        <div
          v-for="(card, index) in filteredCards"
          class="swiper-slide bg-white text-black flex flex-col justify-between rounded-md overflow-hidden"
          :key="index">
          <card :card="card" :marked="marked" :on-card-action="onCardAction" />
        </div>
      </div>
    </div>
    <card
      v-else-if="filteredCards.length === 1"
      class="bg-white text-black flex flex-col justify-between rounded-md"
      :layout="layout"
      :card="filteredCards[0]"
      :marked="marked"
      :on-card-action="onCardAction" />
  </div>
</template>

<script>
// import ZButton from '../../zain-component-library/src/lib-components/ZButton'
import { mapActions } from 'vuex'
import SwiperMixin from '../swiper/SwiperMixin'
let marked = require('marked')

const Card = {
  props: ['card', 'onCardAction', 'marked', 'layout'],
  template: `
    <div><img class="mx-auto w-full" v-if="card.content.images.length > 0" :src="card.content.images[0].contentUrl"
              alt="card image"/>
    <div class="pt-3 text-center px-2" v-if="card.content.title" style="min-height: 4rem">
      {{ card.content.title }}
    </div>
    <div class="mt-1" v-if="card.content.icon">
      <span class="p-4 text-purple-900 dark:text-purple-300 text-6xl" :class="card.content.icon.replace('icon-', 'icon-app-')"/>
    </div>
    <div class="pt-3 text-start px-2 card-cell-text" v-if="card.content.text" v-html="marked(card.content.text)"/>
    <div class="pt-3 text-center px-2 text-secondary font-bold" v-if="card.content.priceText">
      {{ card.content.priceText }}
    </div>
    <div class="p-3 flex flex-wrap gap-3 justify-center">
      <z-button :class="layout === 'list' ? 'w-full': 'flex-1'" class="" @click="onCardAction(btn)" size="small"
                style="min-width: 80px"
                outlined v-for="(btn, bi) in card.content.buttons" :key="bi">
        {{ btn.title }}
      </z-button>
    </div>
    </div>`
}

export default {
  name: 'GenericCardsCell',
  components: { Card },
  mixins: [SwiperMixin],
  props: {
    cards: Array,
    language: String,
    channelData: Object,
    layout: String
  },
  computed: {
    filteredCards() {
      if (this.selectedFilter === 'All') return this.cards

      return this.cards.filter((x) => x.content.filter === this.selectedFilter)
    }
  },
  data() {
    return {
      selectedFilter: 'All'
    }
  },
  methods: {
    ...mapActions('chatModule', ['sendChatMessage']),
    onCardAction(btn) {
      if (btn.type === 'imBack') this.sendChatMessage(btn.value)
      if (btn.type === 'openUrl') window.open(btn.value)
    },
    marked(text) {
      if (!text) return ''

      const renderer = new marked.Renderer()
      const linkRenderer = renderer.link

      renderer.link = (href, title, text) => {
        const html = linkRenderer.call(renderer, href, title, text)
        return html.replace(
          /^<a /,
          '<a target="_blank" style="text-decoration: underline" rel="nofollow" '
        )
      }

      const pRenderer = renderer.paragraph

      renderer.paragraph = (href, title, text) => {
        const html = pRenderer.call(renderer, href, title, text)
        return html.replace(/^<p /, '<p style="white-space: pre-line" ')
      }

      return marked(text, { renderer, gfm: true, breaks: true }).replace(
        '<code>',
        '<code dir="ltr">'
      )
    },
    getFilters() {
      const filters = [...new Set(this.cards.map((x) => x.content.filter))].filter((x) => x)
      return filters.length > 0 ? filters : false
    },
    selectFilter(filter) {
      this.selectedFilter = filter
    }
  },
  mounted() {
    if (this.channelData.selectedFilter) {
      this.selectedFilter = this.channelData.selectedFilter
    }
  }
}
</script>
<style>
.card-cell p:not(:last-child) {
  margin-bottom: 1.2rem;
}

.card-cell-text ul {
  list-style: inside;
  margin-bottom: 1.2rem;
}

.card-cell-text ol {
  list-style: inside decimal;
  margin-bottom: 1.2rem;
}
</style>
