// noinspection DuplicatedCode

import CellTypeEnum from '../components/cells/CellType'
import dl from '../api/direcline'
import * as DirectLine from 'botframework-directlinejs'
import { ATTACHMENT_FILE_TYPES } from '@/data/constants'

export default {
  namespaced: true,
  state: {
    connected: false,
    language: 'en',
    newMsgText: '',
    initialIntent: '',
    userId: null,
    messages: [],
    isLoading: false,
    debug: false,
    fullScreenImg: false,
    fullScreenImgSrc: '',
    intentMap: [],
    initializeNumber: {
      isEnabled: false,
      number: null
    }
  },
  mutations: {
    setMessageText(state, payload) {
      state.newMsgText = payload
    },
    addMessage(state, payload) {
      state.messages = [...state.messages, payload]
      //scroll to the bottom of the page after adding a message
      document.getElementById('app').scrollTo({
        top: document.getElementById('messages-container').scrollHeight + document.getElementById('user-input-bar').scrollHeight,
        behavior: 'smooth'
      })
    },
    setLoading(state, payload) {
      state.isLoading = payload
    },
    setInitialNumber(state, initialNumber) {
      state.initializeNumber.isEnabled = true
      state.initializeNumber.number = initialNumber
    }
  },
  actions: {
    sendChatMessage({ commit, state }, msgText) {
      // console.log(msgText)
      commit('setMessageText', msgText)

      if (!state.newMsgText) return
      if (state.newMsgText === 'debug on') {
        console.log('Debug Mode!')
        state.debug = true
        state.newMsgText = ''

        let ms = createMessage(
          'Bot',
          {
            cellType: CellTypeEnum.SYSTEM_MESSAGE,
            messageData: {
              text: '--- Debug Mode ON! ---'
            }
          },
          '',
          true
        )
        ms.systemMsg = true
        ms.animationClass = 'fadeIn'
        commit('addMessage', ms)
        return
      }
      if (state.newMsgText === 'clear') {
        state.messages = []
        state.newMsgText = ''
        return
      }

      const lang = state.newMsgText.match(/[\u0600-\u06FF]/g) ? 'ar' : 'en'
      state.language = lang

      let tempMessage = state.newMsgText

      // add to messages list
      commit('addMessage', {
        text: tempMessage,
        user: 'User',
        isUserMessage: true,
        payload: {
          cellType: CellTypeEnum.USER_TEXT,
          language: lang,
          id: Math.floor(Math.random() * 100000000000 + 1)
        }
      })

      let finalData = {}

      // send the msg
      dl.post({
        from: {
          id: state.userId,
          name: state.userId,
          role: 'user'
        },
        type: 'message',
        text: state.newMsgText,
        locale: '',
        channelData: finalData
      })
      commit('setMessageText', '')
    },
    sendHiddenMessage({ state }, msg) {
      let message = msg

      // shouldSendSpeech
      dl.post({
        from: {
          id: state.userId,
          name: state.userId
        },
        type: 'message',
        text: message.text,
        locale: message.locale || ''
      })
    },
    subscribeToMessages({ commit, state, dispatch }) {
      dl.subscribeToStatusUpdates((connectionStatus) => {
        // console.log(DirectLine.ConnectionStatus[connectionStatus])
        switch (connectionStatus) {
          case DirectLine.ConnectionStatus.Uninitialized: // the status when the DirectLine object is first created/constructed
            break
          case DirectLine.ConnectionStatus.Connecting: // currently trying to connect to the conversation
            break
          case DirectLine.ConnectionStatus.Online: // successfully connected to the converstaion. Connection is healthy so far as we know.
            commit('addMessage', {
              payload: { cellType: CellTypeEnum.WELCOME },
              isUserMessage: false,
              animationClass: 'fadeIn'
            })
            let hiddenMsg = state.language === 'en' ? 'main menu' : 'القائمة الرئيسية'
            if (state.initialIntent) {
              let intent = state.intentMap.find((x) => x.intent === state.initialIntent)

              if (intent) {
                hiddenMsg = state.language === 'en' ? intent.en : intent.ar
              }
            }

            dispatch('sendHiddenMessage', {
              text: hiddenMsg,
              locale: state.language,
              shouldSendSpeech: true
            })

            if (state.initializeNumber.isEnabled)
              dispatch('sendHiddenMessage', {
                text: state.initializeNumber.number,
                locale: state.language,
                shouldSendSpeech: true
              })

            break
          case DirectLine.ConnectionStatus.ExpiredToken: // last operation errored out with an expired token. Your app should supply a new one.
            dl.reconnect().then(() => console.log('reconnected'))
            break
          case DirectLine.ConnectionStatus.FailedToConnect: // the initial attempt to connect to the conversation failed. No recovery possible.
            let mss = createMessage(
              'Bot',
              {
                cellType: CellTypeEnum.SYSTEM_MESSAGE,
                messageData: {
                  text: 'Failed to connect or session timed out. Please refresh the page or try again later.'
                }
              },
              ''
            )
            mss.systemMsg = true
            mss.isError = true
            mss.animationClass = 'fadeIn'
            commit('addMessage', mss)
            break
          case DirectLine.ConnectionStatus.Ended:
            let mser = createMessage(
              'Bot',
              {
                cellType: CellTypeEnum.SYSTEM_MESSAGE,
                messageData: {
                  text: 'The bot ended the conversation.'
                }
              },
              '',
              true
            )
            mser.systemMsg = true
            mser.isError = true
            mser.animationClass = 'fadeIn'
            commit('addMessage', mser)
            break
        }
      })
      dl.subscribe((message) => {
        if (message.type === 'typing') {
          message.channelData = {}
          message.channelData.cellType = CellTypeEnum.TYPING_INDICATOR
        }
        if (message.channelData === undefined) {
          message.channelData = {}
        }

        let fileAttachment = {
          type: '',
          url: ''
        }

        if (state.debug) {
          console.log(message)
          const normalizedText = message.channelData?.normalizedText
          if (normalizedText) {
            let ms = createMessage(
              'Bot',
              {
                cellType: CellTypeEnum.SYSTEM_MESSAGE,
                messageData: {
                  text: 'Normalized Text: ' + normalizedText
                }
              },
              '',
              true
            )
            ms.systemMsg = true
            ms.animationClass = 'fadeIn'
            commit('addMessage', ms)
          }

          const debugString = `Handler: ${message.channelData?.handler} \nRecognizer: ${message.channelData?.recognizer} \nIntent: ${message.channelData?.recognizerIntent} (${message.channelData?.recognizerThreshold})`
          if (message.channelData?.handler) {
            let ms2 = createMessage(
              'Bot',
              {
                cellType: CellTypeEnum.SYSTEM_MESSAGE,
                messageData: {
                  text: debugString
                }
              },
              '',
              true
            )
            ms2.systemMsg = true
            ms2.animationClass = 'fadeIn'
            commit('addMessage', ms2)
          }

          /*cellType: 1
handler: "None"
normalizedText: "tell balance"
recognizer: "Main ZainNLP English"
recognizerEntities: Array(1)
recognizerIntent: "QNA"
recognizerThreshold: "82.48"*/
        }

        if (
          message.attachments &&
          message.attachments.length > 0 &&
          message.channelData.cellType !== CellTypeEnum.IGNORE
        ) {
          let imgD = message.attachments.find((x) => x.contentType?.startsWith('image/'))
          let pdfD = message.attachments.find((x) => x.contentType?.startsWith('application/pdf'))
          let videoD = message.attachments.find(
            (x) => !x.contentUrl?.includes('youtube.com') && x.contentType?.startsWith('video/mp4')
          )
          let youtubeD = message.attachments.find((x) => x.contentUrl?.includes('youtube.com'))
          if (imgD) {
            fileAttachment.type = ATTACHMENT_FILE_TYPES.IMAGE
            fileAttachment.url = imgD.contentUrl
          }
          if (pdfD) {
            fileAttachment.type = ATTACHMENT_FILE_TYPES.PDF
            fileAttachment.url = pdfD.contentUrl
          }
          if (videoD) {
            fileAttachment.type = ATTACHMENT_FILE_TYPES.VIDEO
            fileAttachment.url = videoD.contentUrl
          }
          if (youtubeD) {
            fileAttachment.type = ATTACHMENT_FILE_TYPES.YOUTUBE
            fileAttachment.url = youtubeD.contentUrl
          }
        }

        if (
          message.channelData.cellType === undefined ||
          message.channelData.cellType === null ||
          message.channelData.cellType === {}
        ) {
          if (!message.channelData) message.channelData = {}
          message.channelData.cellType = CellTypeEnum.BOT_TEXT
        }

        if (message.text === 'Hi!, I\'m zBot') return

        let newMsg = createMessage(
          'Bot',
          {
            cellType: message.channelData.cellType,
            language: message.locale,
            id: message.id,
            replyToId: message.replyToId,
            channelData: message.channelData,
            isHTMLMessage: !!message.channelData?.messageHtml ?? false
          },
          message.text
        )

        // newMsg.payload.channelData = message.channelData

        if (fileAttachment.type) newMsg.payload.fileAttachment = fileAttachment

        // mark message as full width if it contains a <iframe or if it contains a link
        if (message.text && (message.text.includes('<iframe') || message.text.includes('href='))) {
          newMsg.fullWidth = true
        }

        switch (message.channelData.cellType) {
          case CellTypeEnum.BOT_TEXT: {
            //Check if message exists in html format or fallback to normal text (this way, so we can be compatible pre-TipTap content)
            const doesMessageHtmlExist = !!message.channelData?.messageHtml ?? false
            const isGenericCardsLayout = message?.attachments
              ? message.attachments.some(
                  (x) => x.contentType === 'application/vnd.microsoft.card.hero'
                )
              : false

            if (!newMsg.text && !doesMessageHtmlExist && !newMsg.payload?.fileAttachment) break
            if (message.attachmentLayout === 'card') break
            if (isGenericCardsLayout) break
            if (
              !state.debug &&
              newMsg.text &&
              (newMsg.text.startsWith('DEBUG:') || newMsg.text.startsWith('(Debug)'))
            ) {
              return
            }
            newMsg.payload.isBotMessage = true
            if (doesMessageHtmlExist) {
              newMsg.text = message.channelData.messageHtml
              newMsg.isHTMLMessage = true
            }
            commit('addMessage', newMsg)
            break
          }
          case CellTypeEnum.GENERIC_ACTION_BUTTONS:
          case CellTypeEnum.HELP:
            newMsg.payload.title = message.text
            newMsg.payload.helpInfo = message.channelData.APIResponse
              ? JSON.parse(message.channelData.APIResponse)
              : {}
            commit('addMessage', newMsg)
            break
          case CellTypeEnum.TYPING_INDICATOR:
            commit('addMessage', newMsg)
            break
          case CellTypeEnum.IGNORE:
            break
          default:
            console.warn(
              'WARNING: UNHANDLED MESSAGE TYPE RECEIVED! --> ' + message.channelData.cellType
            )
            console.warn(message)
            break
        }

        const genericCards = message.attachments
          ? message.attachments.filter(
              (x) => x.contentType === 'application/vnd.microsoft.card.hero'
            )
          : []
        //

        if (genericCards.length > 0) {
          let genericCardMessage
          if (message.attachmentLayout === 'buttons') {
            let newMsg = createMessage(
              'Bot',
              {
                cellType: CellTypeEnum.BOT_TEXT,
                language: message.locale,
                id: message.id,
                replyToId: message.replyToId,
                channelData: message.channelData
              },
              genericCards?.[0]?.content?.text
            )
            newMsg.payload.isBotMessage = true
            commit('addMessage', newMsg)

            genericCardMessage = createMessage(
              'Bot',
              {
                cellType: CellTypeEnum.QUICK_ACTIONS,
                language: message.locale,
                id: message.id,
                replyToId: message.replyToId,
                suggestedActions: { actions: genericCards?.flatMap((x) => x?.content?.buttons) },
                channelData: message.channelData
              },
              message.text
            )
          } else if (message.attachmentLayout === 'carousel-buttons') {
            genericCardMessage = createMessage(
              'Bot',
              {
                cellType: CellTypeEnum.HELP,
                language: message.locale,
                id: message.id,
                replyToId: message.replyToId,
                actions: genericCards?.flatMap((x) => x?.content?.buttons),
                channelData: message.channelData
              },
              message.text
            )
          } else {
            genericCardMessage = createMessage(
              'Bot',
              {
                cellType: CellTypeEnum.GENERIC_CARDS_CELL,
                language: message.locale,
                id: message.id,
                replyToId: message.replyToId,
                cards: genericCards,
                channelData: message.channelData,
                layout: message.attachmentLayout
              },
              message.text
            )
          }

          genericCardMessage.nobg = true
          commit('addMessage', genericCardMessage)
        }

        if (message.suggestedActions && message.suggestedActions?.actions?.length > 0) {
          let suggestedActionsMessage = createMessage(
            'Bot',
            {
              cellType: CellTypeEnum.QUICK_ACTIONS,
              language: message.locale,
              id: message.id,
              replyToId: message.replyToId,
              suggestedActions: message.suggestedActions,
              channelData: message.channelData
            },
            message.text
          )
          commit('addMessage', suggestedActionsMessage)
        }
      })
    }
  }
}

function createMessage(userName, passedPayload, messageText) {
  let m = {
    user: userName,
    isUserMessage: false,
    payload: passedPayload
  }
  if (messageText) m.text = messageText
  return m
}
