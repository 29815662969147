<template>
  <div class="text-center p-3 bg-white rounded-md shadow-md">
    <img alt="Welcome" src="salama-logo.png" class="h-16 mx-auto my-3" />
<!--    <h5 style="direction: rtl">مرحبا، أنا <b>Zayna</b>. كيف يمكنني مساعدتك؟</h5>-->
    <h5>Hello, I am <b>Salama</b>. and I will be your health coach.  My goal is for us to work together to achieve physical and mental wellness.</h5>
  </div>
</template>
<script>
export default {
  name: 'Welcome'
}
</script>
