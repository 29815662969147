<template>
  <div class="flex flex-col h-full w-full">
    <div class="flex-grow max-w-3xl w-full mx-auto px-4" ref="messages" id="messages-container">
      <div v-if="messages.length === 0" class="flex justify-center mt-16">
        <div class="the-spinner">
          <div class="dot1"></div>
          <div class="dot2"></div>
        </div>
      </div>
      <ul v-else class="mt-4 mb-20 flex flex-col space-y-3">
        <message-cell
          class="animated w-auto inline-block self-start"
          v-for="(msg, index) in messages"
          :key="index"
          :text="msg.text"
          :user="msg.user"
          :payload="msg.payload"
          :language="msg.language"
          :class="bindMessageClasses(msg)">
        </message-cell>
      </ul>
    </div>
    <user-input-bar />
  </div>
</template>

<script>
import Cell from '../components/cells/Cell'
import UserInputBar from './UserInputBar'
import { mapActions, mapState } from 'vuex'
import dl from '../api/direcline'
import '../assets/css/styles.css'

export default {
  name: 'ChatView',
  data() {
    return {
      connected: false
    }
  },
  computed: {
    ...mapState('chatModule', {
      messages: (state) => {
        return state.messages.filter((x, index, array) => {
          // if typing indicator check whether to show it or not if it has a message after it
          if (x.payload.cellType === 97) {
            return array[index + 1] === undefined
          } else {
            return true
          }
        }, [])
      }
    })
  },
  methods: {
    ...mapActions('chatModule', ['sendChatMessage', 'subscribeToMessages']),
    bindMessageClasses(msg) {
      let obj = {
        'text-right self-end': msg.isUserMessage,
        fadeInLeft: !msg.animationClass && !msg.isUserMessage,
        fadeInRight: !msg.animationClass && msg.isUserMessage,
        'w-full':
          (msg.payload.cellType !== 1 &&
            msg.payload.cellType !== 0 &&
            msg.payload.cellType !== 8 &&
            msg.payload.cellType !== 20 &&
            msg.payload.cellType !== 19 &&
            msg.payload.cellType !== 97) ||
          msg.fullWidth
      }
      if (msg.animationClass) {
        obj[msg.animationClass] = true
      }
      return obj
    }
  },
  async mounted() {
    if (!this.$store.state.chatModule.connected) {
      this.$store.state.chatModule.connected = true

      const savedToken = localStorage.getItem('z-token')

      const { userId, token } = await dl.init(savedToken)

      this.$store.state.chatModule.userId = userId

      localStorage.setItem('z-token', token)

      this.subscribeToMessages()
    }
  },
  components: {
    'message-cell': Cell,
    'user-input-bar': UserInputBar
  },
  updated() {
    setTimeout(function () {
      window.scrollTo(0, document.body.scrollHeight)
    }, 50)
  }
}
</script>

<style scoped>
.sys-msg {
  background: #2b2b2b52;
  padding: 5px 10px;
  font-size: 0.9em;
  color: white;
  box-shadow: none;
  line-height: 1;
  left: 50%;
  transform: translateX(-50%);
}

.sys-msg.is-error {
  color: #ff6b6b;
}

.is-hidden {
  display: none !important;
}
</style>
