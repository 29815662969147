<template>
  <li>
    <div
      :cellText="text"
      v-bind="payload"
      :is="'c-' + payload.cellType.toString().padStart(2, '0')"></div>
  </li>
</template>
<script>
import HelpCell from './HelpCell'
import LoginCell from './LoginCell'
import WelcomeCell from './WelcomeCell'
import TextCell from './TextCell'
import SystemMessageCell from './SystemMessageCell'
import TypingCell from './TypingIndicatorCell'
import GenericActionButtonsCells from './GenericActionButtonsCell'
import QuickActionsCell from './QuickActionsCell'
import GenericCardsCell from './GenericCardsCell'

export default {
  name: 'Cell',
  props: {
    user: { type: String },
    text: { type: String },
    language: { type: String },
    payload: {
      type: Object,
      default: function () {
        return { cellType: 0, data: {} }
      }
    }
  },
  // corresponds to cell type enum (CellType.js)
  components: {
    'c-00': TextCell,
    'c-01': TextCell,
    'c-07': HelpCell,
    'c-08': LoginCell,
    'c-18': WelcomeCell,
    'c-19': SystemMessageCell,
    'c-25': GenericActionButtonsCells,
    'c-26': QuickActionsCell,
    'c-30': GenericCardsCell,
    'c-97': TypingCell,
  }
}
</script>
