/* eslint-disable no-unused-vars */
let CellTypeEnum
export default CellTypeEnum = {
  USER_TEXT: 0,
  BOT_TEXT: 1,
  HELP: 7,
  LOGIN: 8,
  WEATHER: 17,
  WELCOME: 18,
  SYSTEM_MESSAGE: 19,
  GENERIC_ACTION_BUTTONS: 25,
  QUICK_ACTIONS: 26,
  GENERIC_CARDS_CELL: 30,
  TYPING_INDICATOR: 97,
  IGNORE: -1
}
