<template>
  <div
      v-if="!!cellText"
    :dir="language === 'ar' ? 'rtl' : 'ltr'"
    class="p-3 rounded-md shadow-md text-cell"
    :class="{
      'bg-white': isBotMessage,
      'bg-green-veryLight': !isBotMessage,
      'message-html': isHTMLMessage
    }">
    <div v-if="fileAttachment.url" v-switch="fileAttachment.type" class="text-center">
      <div v-case="ATTACHMENT_FILE_TYPES.IMAGE">
        <br />
        <img
          @click="
            ;($store.state.fullScreenImg = true) &&
              ($store.state.fullScreenImgSrc = fileAttachment.url)
          "
          style="border-radius: 10px; max-height: 350px"
          alt="attachment"
          :src="fileAttachment.url" />
        <br />
      </div>
      <embed
        v-case="ATTACHMENT_FILE_TYPES.PDF"
        :src="fileAttachment.url + '#view=FitH&toolbar=1&navpanes=0'"
        type="application/pdf"
        frameBorder="0"
        scrolling="auto"
        style="height: 400px; min-width: 300px; max-width: 80vw" />
      <video
        v-case="ATTACHMENT_FILE_TYPES.VIDEO"
        controls
        autoplay
        muted
        loop
        style="height: 400px; min-width: 300px; max-width: 80vw">
        <source :src="fileAttachment.url + '#t=0.1'" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <iframe
        v-case="ATTACHMENT_FILE_TYPES.YOUTUBE"
        :src="getVideoEmbedYTLink"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>
    <div
      class="text-start text-cell-text"
      :class="fileAttachment.url !== '' ? 'pt-1' : ''"
      v-html="marked(cellText)"></div>
  </div>
</template>

<script>
import { ATTACHMENT_FILE_TYPES } from '@/data/constants'
let marked = require('marked')
export default {
  name: 'TextCell',
  props: {
    isHTMLMessage: {
      type: Boolean,
      default: false
    },
    cellText: String,
    language: String,
    isBotMessage: Boolean,
    //Images, PDFs, videos attached to the payload
    fileAttachment: {
      type: Object,
      default: function () {
        return { type: '', url: '' }
      }
    },
    audioData: {
      type: Object,
      default: function () {
        return { duration: 0, hasAudio: false }
      }
    }
  },
  data() {
    return {
      animate: false,
      fullScreenImg: false,
      ATTACHMENT_FILE_TYPES
    }
  },
  created() {
    setTimeout(() => {
      this.animate = true
    }, 100)
  },
  computed: {
    getVideoEmbedYTLink() {
      if (this.fileAttachment?.url === '') return ''
      const urlParams = new URLSearchParams(
        this.fileAttachment.url.split('/watch')[this.fileAttachment.url.split('/watch').length - 1]
      )
      const videoId = urlParams.get('v')
      return 'https://www.youtube.com/embed/' + videoId
    }
  },
  methods: {
    marked(text) {
      if (!text) return ''

      //NOTE: remove the <p> tags to make the markdown parser parse correctly
      if (this.isHTMLMessage && text.slice(0, 3) === '<p>') {
        text = text.slice(3, -4)
      }

      const renderer = new marked.Renderer()
      const linkRenderer = renderer.link

      renderer.link = (href, title, text) => {
        const html = linkRenderer.call(renderer, href, title, text)
        return html.replace(
          /^<a /,
          '<a target="_blank" style="text-decoration: underline" rel="nofollow" '
        )
      }

      const pRenderer = renderer.paragraph

      renderer.paragraph = (href, title, text) => {
        const html = pRenderer.call(renderer, href, title, text)
        return html.replace(/^<p /, '<p style="white-space: pre-line" ')
      }

      return marked(text, { renderer, gfm: true, breaks: true }).replace(
        '<code>',
        '<code dir="ltr">'
      )
    }
  }
}
</script>

<style>
.text-cell:not(.message-html) p:not(:last-child) {
  margin-bottom: 1.2rem;
}

.text-cell-text ul {
  list-style: inside;
}

.text-cell-text ol {
  list-style: inside decimal;
}
</style>
