<template>
  <div class="gap-2 flex flex-wrap h-full">
    <a
      @click="dispatchQuickAction(action)"
      class="cursor-pointer py-4 px-4 flex flex-col rounded-md font-bold flex-1 text-xs bg-white"
      v-for="action in suggestedActions.actions">
      <div v-if="action.icon" class="w-full py-2">
        <i class="fa fa-2x" :class="action.icon" style="max-width: 120px" />
      </div>
      <img
        v-if="action.image"
        :src="action.image"
        class="mx-auto flex-grow block w-auto h-auto object-contain p-2"
        :style="`max-width: 800px; max-height: ${ handler === 'This Or That Dietary Habits Game' ? 200 : 90}px`"
        alt="action" />
      <br v-if="action.image" />
      {{ action.title }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'QuickActionsCell',
  props: {
    suggestedActions: {
      type: Object,
      default: () => {
        return { actions: [{ title: 'title', value: 'value', type: 'imBack' }] }
      }
    }
  },
  computed: {
    handler() {
      return this.$parent.$props?.payload?.channelData?.handler
    }
  },
  data() {
    return {
      respondedToQuickAction: false
    }
  },
  methods: {
    dispatchQuickAction(action) {
      this.respondedToQuickAction = true
      if (action.type === 'imBack') this.$store.dispatch('chatModule/sendChatMessage', action.value)
      if (action.type === 'openUrl') window.open(action.value)
    }
  }
}
</script>
